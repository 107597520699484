import React from 'react';
import Select from 'react-select';
import { FormGroup, FormFeedback, Label } from 'reactstrap';
import { Trans } from '@lingui/macro';

export default function RFReactSelect({
    input,
    disabled,
    options,
    multi,
    className,
    label,
    feedbackIcon,
    placeholder = <Trans>Välj</Trans>,
    isClearable = false,
    meta: { error, warning, touched },
}) {
    const { name, value, onBlur, onChange, onFocus } = input;
    const transformedValue = transformValue(value, options, multi);

    let message;
    if (touched && (error || warning)) {
        message = <span className="help-block">{error || warning}</span>;
    }

    return (
        <FormGroup>
            {label && <Label>{label}</Label>}
            <Select
                name={name}
                value={transformedValue}
                isMulti={multi}
                options={options}
                onChange={multi ? multiChangeHandler(onChange) : singleChangeHandler(onChange)}
                onBlur={() => onBlur(value)}
                onFocus={onFocus}
                className={className}
                disabled={disabled}
                placeholder={placeholder}
                isClearable={isClearable}
                isOptionDisabled={(option) => option.disabled === true}
            />
            {feedbackIcon ? <FormFeedback>{feedbackIcon}</FormFeedback> : null}
            {message}
        </FormGroup>
    );
}

/**
 * onChange from Redux Form Field has to be called explicity.
 */
function singleChangeHandler(func) {
    return function handleSingleChange(value) {
        func(value ? value.value : '');
    };
}

/**
 * onBlur from Redux Form Field has to be called explicity.
 */
function multiChangeHandler(func) {
    return function handleMultiHandler(values) {
        if (values) {
            func(values.map((value) => value.value));
        } else {
            func(values);
        }
    };
}

/**
 * For single select, Redux Form keeps the value as a string, while React Select
 * wants the value in the form { value: "grape", label: "Grape" }
 *
 * * For multi select, Redux Form keeps the value as array of strings, while React Select
 * wants the array of values in the form [{ value: "grape", label: "Grape" }]
 */
function transformValue(value, options, multi) {
    if (multi && typeof value === 'string') return [];

    const filteredOptions = options.filter((option) => {
        return multi ? value.indexOf(option.value) !== -1 : option.value === value;
    });

    return multi ? filteredOptions : filteredOptions[0];
}
