// @ts-check
import React, { useEffect, createRef, useMemo, useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    Card,
    CardBody,
    Alert,
    Badge,
    Navbar,
    Nav,
    NavItem,
    NavLink,
    Input
} from 'reactstrap';
import queryString from 'query-string';
import classNames from 'classnames/bind';
import { Trans } from '@lingui/macro';
import { t } from '@lingui/macro';
import ModalVideo from 'react-modal-video';
import { FaQuestionCircle } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';

import CopyToClipboard from './CopyToClipboard';
import { throttle } from 'throttle-debounce';
import styles from './Previewer.module.css';
import { getFormValues, Field, reduxForm, formValueSelector } from 'redux-form';
import { withI18n } from '@lingui/react';
import ServicesAPI from '../api/services';
import Obfuscator from './Obfuscator';
import LanguageSelect from './LanguageSelect';

const cx = classNames.bind(styles);

// console.log(t);
const a = 1;

// Should buildEmbeddedURL load booking widget URL or Profile widget URL?

function buildEmbeddedURL(configuration, redn) {
    let urlParameters = {
        company: configuration.company,
        bookMethods: configuration.bookMethods ? configuration.bookMethods.join(',') : undefined,
        serviceIdsToShow: configuration.serviceIdsToShow
            ? configuration.serviceIdsToShow.join(',')
            : undefined,
        articleIdsToShow: configuration.articleIdsToShow
            ? configuration.articleIdsToShow.join(',')
            : undefined,
        bookLayout: configuration.bookLayout,
        timesLayout: configuration.timesLayout,
        listingLayout: configuration.listingLayout,
        showNextAvailableTime: configuration.showNextAvailableTime,
        showCompanySummary: configuration.showCompanySummary,
        hideServiceImage: configuration.hideServiceImage,
        hideServiceInfo: configuration.hideServiceInfo,
        showEndTimeOnTimeslots: configuration.showEndTimeOnTimeslots,
        selectedService: configuration.selectedService,
        bookedTimeSlotText: configuration.bookedTimeSlotText,
        language: configuration.language,
        topOffset: configuration.topOffset,
        primaryColor: configuration.primaryColor,
        translations: configuration.translations
            ? JSON.stringify(configuration.translations)
            : undefined,
        darkTheme: configuration.darkTheme,
        paymentConfirmationURL: configuration.paymentConfirmationURL,
        targetOrigin: configuration.targetOrigin,
        preventAutoscroll: configuration.preventAutoscroll,
        scrollAfterSteps: configuration.scrollAfterSteps
    };

    return `${process.env.REACT_APP_EMBED_URL}?${queryString.stringify(
        urlParameters
    )}`;
}

const Preview = ({ configuration, scrollingElement }) => {
    const withoutTargetOrigin = {
        ...configuration,
        targetOrigin: window.location.origin
    };
    
    const URL = buildEmbeddedURL(withoutTargetOrigin);
    
    window.BOKAMERA = {
        configuration: withoutTargetOrigin
    }

    const addScript = useCallback(throttle(300, () => {
        const embedScriptId = 'embed-script';
        const script = document.createElement('script');

        script.src = process.env.REACT_APP_EMBED_JS;
        script.id = embedScriptId;
        const scriptSrc = process.env.REACT_APP_EMBED_JS;
        
        document.querySelectorAll(`script[src="${scriptSrc}"]`).forEach(el => el.remove());
        document.head.appendChild(script);
    }), []);

    useEffect(() => {
        addScript();
    }, [URL]);

    return configuration.company ? (
        <div key={URL} id="bokamera-embedded" />
    ) : (
        <Trans>No company is configured!</Trans>
    );
};

export class Previewer extends React.Component {
    constructor(props) {
        super(props);

        const existingStyleNode = document.getElementById('bokamera-embedded-style-id');
        if (existingStyleNode)
            document.getElementsByTagName('head')[0].removeChild(existingStyleNode);
    }

    state = {
        activeTabIndex: 0,
        videoOpen: false,
        isCompanyAccountFree: undefined
    };

    el = createRef();

    handleTabChange = (tabIndex) => {
        this.setState({ activeTabIndex: tabIndex });
    };

    toggleVideoOpen = () => {
        this.setState({ videoOpen: !this.state.videoOpen });
    };

    checkCompany = () => {
        const { configuration } = this.props;
        const { company: companyId} = configuration;

        if (companyId) {
            ServicesAPI.getCompany(companyId)
                .then((response) => {
                    const company = response.Results && response.Results.length > 0
                        ? response.Results[0]
                        : { IsFreeAccount: true };
                    this.setState({ isCompanyAccountFree: company.IsFreeAccount });
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    componentDidMount() {
        this.checkCompany();
    }

    componentDidUpdate(prevProps, prevState) {
        const { configuration } = this.props;
        const { company: companyId} = configuration;
        const { configuration: prevConfiguration } = prevProps;
        const { company: prevCompanyId} = prevConfiguration;

        if(
            (prevState.companyId !== this.state.companyId) ||
            (prevCompanyId !== companyId)) {
            this.checkCompany();
        }
    }



    render() {
        const { configuration, i18n, formValues } = this.props;
        const { activeTabIndex } = this.state;

        return (
            <div className={cx('container', { dark: configuration.darkTheme })}>
                <Navbar className={cx('navbar')} expand="md">
                    <Nav className="mr-auto" navbar pills>
                        <NavItem
                            active={activeTabIndex === 0}
                            onClick={() => this.handleTabChange(0)}
                        >
                            <NavLink href="#" active={activeTabIndex === 0}>
                                <Trans>Konfigurering</Trans>
                            </NavLink>
                        </NavItem>
                        <NavItem
                            active={activeTabIndex === 1}
                            onClick={() => this.handleTabChange(1)}
                        >
                            <NavLink href="#" active={activeTabIndex === 1}>
                                <Trans>Bokning widget instruktioner</Trans>
                            </NavLink>
                        </NavItem>
                        <NavItem
                            active={activeTabIndex === 2}
                            onClick={() => this.handleTabChange(2)}
                        >
                            <NavLink href="#" active={activeTabIndex === 2}>
                                <Trans>Profil widget Instruktioner</Trans>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <Nav>
                        {i18n._({ id: 'helpLink', default: '-' }) !== '-' && (
                            <NavItem>
                                <a href={i18n._({ id: 'helpLink' })}>
                                    <FaQuestionCircle className={cx('nav-icon')} />
                                </a>
                            </NavItem>
                        )}
                        {i18n._({ id: 'youtubeVideoId', default: '-' }) !== '-' && (
                            <NavItem>
                                <FaYoutube
                                    aria-label="Open video"
                                    className={cx('nav-icon')}
                                    onClick={this.toggleVideoOpen}
                                />
                            </NavItem>
                        )}
                        <NavItem style={{ minWidth: '200px'}}>
                            <LanguageSelect />
                        </NavItem>
                    </Nav>
                </Navbar>
                <ModalVideo
                    channel="youtube"
                    isOpen={this.state.videoOpen}
                    videoId={i18n._({ id: 'youtubeVideoId' })}
                    onClose={this.toggleVideoOpen}
                />
                <div ref={this.el} className={cx('main')}>
                    {!configuration.company && (
                        <Alert color="danger">
                            <Trans>Spara en konfiguration för att se förhandsvisningen</Trans>
                        </Alert>
                    )}
                    {activeTabIndex === 0 && (
                        <Preview scrollingElement={this.el} configuration={configuration} />
                    )}
                    {activeTabIndex === 1 && this.renderBookingConfiguration(configuration)}
                    {activeTabIndex === 2 && this.renderProfileConfiguration(configuration)}
                </div>
            </div>
        );
    }

    renderBookingConfiguration = (configuration) => {
        const { i18n, formValues } = this.props;
        const { isCompanyAccountFree } = this.state;
        const adminUrl = process.env.REACT_APP_ADMIN_URL;

        return (
            <div>
                <form>
                <Card className='info'>
                    <CardBody>
                        <Trans
                            id="previewer.customSiteInstructions"
                            values={{
                                link: <a href={`${adminUrl}/#/company/settings`}>link</a>,
                            }}
                        />
                    </CardBody>
                </Card>
                <Card style={{ marginBottom: 10 }}>
                    <CardBody>
                        <h4>
                            <Trans>Method 1: Infällning</Trans>
                            <Badge color="success" style={{ marginLeft: 10 }}>
                                <Trans>Rekommenderad</Trans>
                            </Badge>
                        </h4>
                        <em>
                            <Trans id="">
                                Infällning med hjälp av javascript. Du kan styra utseendet via
                                inställningarna i admin under menyvalet Hemsidan-Inställningar.
                            </Trans>
                        </em>
                        <ol style={{ marginTop: 10 }}>
                            <li>{this.renderConfigurationForCopy(configuration)}</li>
                            <li>
                                <h5>
                                    <Trans>
                                        Placera följande kod där du vill att bokningslösningen ska
                                        hamna. Lösningen kommer automatiskt att skala till att
                                        använda maximal bredd samt den höjd som behövs.
                                    </Trans>
                                </h5>
                                <Obfuscator
                                    obfuscate={isCompanyAccountFree === true}
                                    reason={i18n._(t('obfuscator.enabledForPaidCustomers')``)}
                                >
                                    <CopyToClipboard value={`<div id="bokamera-embedded"></div>`} />
                                </Obfuscator>
                            </li>
                            <li>
                                <h5>Placera följande kod längst ner i {'<body>'} taggen</h5>
                                <Obfuscator
                                    obfuscate={isCompanyAccountFree === true}
                                    reason={i18n._(t('obfuscator.enabledForPaidCustomers')``)}
                                >
                                    <CopyToClipboard value={`<script src="${process.env.REACT_APP_EMBED_JS}"></script>`} />
                                </Obfuscator>
                            </li>
                        </ol>
                    </CardBody>
                </Card>
                {this.renderDirectMethodCard(configuration)}
                <Card style={{ marginBottom: 10 }}>
                    <CardBody>
                        <h4>
                            <Trans id="previewer.addYourOwnCss.title" />
                        </h4>
                        <p><Trans id="previewer.addYourOwnCss.description" /></p>
                        <CopyToClipboard
                            multiline
                            value={`
<div id="bokamera-embedded">
    <style>
        #bokamera-embedded .CalendarWeek__body {
            gap: 3px;
        }
    
        #bokamera-embedded .CalendarWeek__column {
            gap: 3px;
        }
    
        #bokamera-embedded .CalendarWeek__timeSlot {
            color: #333;
            background-color: white;
            transition: all 0.1s cubic-bezier(.17,.67,.83,.67);
            box-shadow: inset 0 0 0.5px 1px hsla(0, 0%,  
                100%, 0.075),
                0 0 0 1px hsla(0, 0%, 0%, 0.05),
                0 0.3px 0.4px hsla(0, 0%, 0%, 0.02),
                0 0.9px 1.5px hsla(0, 0%, 0%, 0.045),
                0 3.5px 6px hsla(0, 0%, 0%, 0.09);
        }
    
        #bokamera-embedded .CalendarWeek__timeSlot:hover {
            transform: scale(1.1);
            border-color: #212121;
        }
    </style>
</div>
`}
                        />
                            <p>
                                <Trans id="previewer.orAddCssViaJS" />
                            </p>
                            <Input tag={Field} name="jsStyle" id="jsStyle" component="textarea" rows={10} />
                            
                            <p></p>
                            <CopyToClipboard
                                multiline
                                value={`<script>
    function addStyle() {
        const appContainer = document.getElementById("bokamera-embedded");
        const style = document.createElement("style");


        style.innerHTML = \`${formValues.jsStyle}\`;
        appContainer.prepend(style);
    }

    if (document.readyState === "loading") {
        document.addEventListener("DOMContentLoaded", addStyle);
    } else {
        addStyle()
    }
</script>`}
                            />
                        </CardBody>
                </Card>
                </form>
            </div>
        );
    };

    renderIframeForCopy = (configuration) => {
        const URL = buildEmbeddedURL(configuration);

        return <CopyToClipboard value={`<iframe width="100%" height="640" src="${URL}" />`} />;
    }

    renderConfigurationForCopy = (configuration) => {
        const { i18n } = this.props;
        const { isCompanyAccountFree } = this.state;

        let _configuration = {...configuration};
        delete _configuration._targetOrigin;

        return (
            <React.Fragment>
                <h5>
                    <Trans>Placera följande kod i {'<head>'} taggen</Trans>
                </h5>
                <Obfuscator
                    obfuscate={isCompanyAccountFree === true}
                    reason={i18n._(t('obfuscator.enabledForPaidCustomers')``)}
                >
                    <CopyToClipboard
                        multiline
                        value={`
    <script type="text/javascript">
    window.BOKAMERA = ${JSON.stringify({ configuration: _configuration  }, null, 4)}
    </script>`}
                    />
                </Obfuscator>
            </React.Fragment>
        );
    }

    renderProfileConfiguration = (configuration) => {
        const { i18n } = this.props;
        const { isCompanyAccountFree } = this.state;
        const adminUrl = process.env.REACT_APP_ADMIN_URL;

        return (
            <div>
                <Card className='info'>
                    <CardBody>
                        <Trans
                            id="previewer.customSiteInstructions"
                            values={{
                                link: <a href={`${adminUrl}/#/company`}>link</a>,
                            }}
                        />
                    </CardBody>
                </Card>
                <Card style={{ marginBottom: 10 }}>
                    <CardBody>
                        <h4>
                            <Trans>Method 1: Infällning</Trans>
                            <Badge color="success" style={{ marginLeft: 10 }}>
                                <Trans>Rekommenderad</Trans>
                            </Badge>
                        </h4>
                        <em>
                            <Trans>
                                Infällning med hjälp av javascript. Fördelen är att du får en
                                lösning som är en del av din egna sida. Du kan styra hur stort
                                utrymme den ska ta samt lösningen kommer anpassa höjden automatiskt
                                (till skillnad från iframe-lösningen). Du kan även styla utseendet
                                med hjälp av CSS om så önskas.
                            </Trans>
                        </em>
                        <ol style={{ marginTop: 10 }}>
                            <li>{this.renderConfigurationForCopy(configuration)}</li>
                            <li>
                                <h5>
                                    <Trans>
                                        Placera följande kod där du vill att bokningslösningen ska
                                        hamna. Lösningen kommer automatiskt att skala till att
                                        använda maximal bredd samt den höjd som behövs.
                                    </Trans>
                                </h5>
                                <Obfuscator
                                    obfuscate={isCompanyAccountFree === true}
                                    reason={i18n._(t('obfuscator.enabledForPaidCustomers')``)}
                                >
                                    <CopyToClipboard
                                        value={`<div id="bokamera-embedded-profile"></div>`}
                                    />
                                </Obfuscator>
                            </li>
                            <li>
                                <h5>
                                    <Trans>
                                        Placera följande kod längst ner i {'<body>'} taggen
                                    </Trans>
                                </h5>
                                <Obfuscator
                                    obfuscate={isCompanyAccountFree === true}
                                    reason={i18n._(t('obfuscator.enabledForPaidCustomers')``)}
                                >
                                    <CopyToClipboard value={`<script src="${process.env.REACT_APP_PROFILE_JS}"></script>`} />
                                </Obfuscator>
                            </li>
                        </ol>
                    </CardBody>
                </Card>
                {this.renderDirectMethodCard(configuration)}
            </div>
        );
    };


    renderDirectMethodCard(configuration) {
        const URL = buildEmbeddedURL(configuration);

        const { i18n } = this.props;
        const { isCompanyAccountFree } = this.state;

        return <Card style={{ marginBottom: 20 }}>
            <CardBody>
                <h4>
                    <Trans>Method 2: Direktlänk</Trans>
                </h4>
                <em>
                    <Trans>
                        Om du hellre vill länka till BokaMera direkt så går detta också bra.
                </Trans>
                </em>
                <ol style={{ marginTop: 10 }}>
                    <li>
                        <h5>
                            <Trans>Länka till följande URL</Trans>
                        </h5>
                        <Obfuscator
                            obfuscate={isCompanyAccountFree === true}
                            reason={i18n._(t('obfuscator.enabledForPaidCustomers')``)}
                        >
                            <CopyToClipboard value={URL} />
                        </Obfuscator>
                        <a href={URL} target="_blank">
                            <Trans>Testa länken här</Trans>
                        </a>
                    </li>
                </ol>
            </CardBody>
        </Card>
    }
}

// Remove null values
const sanitizeConfiguration = (configuration) => {
    return configuration ? Object.keys(configuration).reduce((acc, key) => {
        const value = configuration[key];
        if (value === null || value === '') {
            return acc;
        } else {
            return { ...acc, [key]: value };
        }
    }, {}) : {};
};

function mapStateToProps(state) {
    const configuration = getFormValues('configuration')(state);
    const selector = formValueSelector('previewer');
    const jsStyle = selector(state, 'jsStyle')


    return {
        configuration: sanitizeConfiguration(configuration),
        formValues: {
            jsStyle
        }
    }
        
}

export default compose(
    connect(mapStateToProps),
    withI18n(),
    reduxForm({
        form: 'previewer',
        initialValues: {
            jsStyle: `#bokamera-embedded .container {
    --bokamera-primary: #b784a7;
}
              
.Button {
    background: #805c7d;
    color: #FFFFFF;
}`
        }
    })
)(Previewer);
