import qs from 'qs';
import pick from 'lodash/pick';

function getFormInitialValues(query) {
    const parsed = qs.parse(query.slice(1), {});
    const parsedTranslations = parsed.translations ? JSON.parse(parsed.translations) : [];

    const defaults = {
        bookMethods: ['contact-information', 'login', 'login-facebook'],
        bookLayout: 'service-based',
        timesLayout: 'week',
        listingLayout: 'rows',
        language: 'sv',
        topOffset: 0,
        preventAutoscroll: false,
        scrollAfterStep: 0

    };

    const bookMethods = parsed.bookMethods
        ? parsed.bookMethods.split(',')
        : ['contact-information', 'login', 'login-facebook'];
    const serviceIdsToShow = parsed.serviceIdsToShow
        ? parsed.serviceIdsToShow.split(',')
        : undefined;
    const articleIdsToShow = parsed.articleIdsToShow
        ? parsed.articleIdsToShow.split(',')
        : undefined;
    const showNextAvailableTime = parsed.showNextAvailableTime === 'true';
    const hideServiceImage = parsed.hideServiceImage === 'true';
    const hideServiceInfo = parsed.hideServiceInfo === 'true';
    const showEndTimeOnTimeslots = parsed.showEndTimeOnTimeslots === 'true';
    const darkTheme = parsed.darkTheme === 'true';
    const translations = Object.keys(parsedTranslations).map((key) => ({
        key,
        value: parsedTranslations[key],
    }));
    const preventAutoscroll = parsed.preventAutoscroll === 'true';
    const topOffset = parsed.topOffset || 0;
    const scrollAfterStep = parsed.scrollAfterStep || 0;

    const values = Object.assign(
        {},
        defaults,
        pick(
            parsed,
            'company',
            'serviceIdsToShow',
            'articleIdsToShow',
            'bookLayout',
            'timesLayout',
            'listingLayout',
            'selectedService',
            'bookedTimeSlotText',
            'language',
            'paymentConfirmationURL',
            'topOffset',
            'scrollAfterStep'
        ),
        {
            bookMethods,
            serviceIdsToShow,
            articleIdsToShow,
            showNextAvailableTime,
            hideServiceImage,
            hideServiceInfo,
            showEndTimeOnTimeslots,
            darkTheme,
            translations,
            preventAutoscroll,
            topOffset,
            scrollAfterStep
        }
    );

    return values;
}

export default getFormInitialValues;
